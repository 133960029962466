import React, {useContext, useEffect} from 'react';
import { withTranslation } from 'react-i18next';
import './Reviews.css';
import { RentalContext } from '../../context';
//import ReviewForm from '../../components/Reviews/ReviewForm';
import {Review} from '../../components/Reviews/Review';
import {ReviewStars} from '../../components/Reviews/ReviewStars';

export function averageStarsForReviews(reviews) {
    let sumStars = 0;
    if (!Array.isArray(reviews)) {
        return 0;
    }
    reviews.forEach(element => {
        sumStars += element['stars'];
    });
    return sumStars / reviews.length;
}

function Reviews(props){
    ////console.log('rendering home');
    // https://css-tricks.com/five-methods-for-five-star-ratings/
    // https://developers.google.com/search/docs/data-types/review-snippet?hl=de#simple-review-example
    // https://github.com/dreyescat/react-rating
    
    const {t} = props;
    const context = useContext(RentalContext);
    const { reviews, loadReviews } = context;
    
    useEffect(() => {
        loadReviews();
    });
  
    const loadingContent = <div className='container-fluid loading'></div>;
    if (!reviews) {
        return loadingContent;
    }

    const lastElement = reviews[reviews.length - 1];
    const avgStars = averageStarsForReviews(reviews);

    return (
        <div className="Reviews container-fluid">
            <div className="p-3 container">
                <h1>{t('reviews')}</h1>
                <div itemProp="itemReviewed" itemScope itemType="http://schema.org/Organization">
                    <meta itemProp="name" content= {t('common:MeinWohnmobil.com')} />
                    <meta itemProp="url" content={'https://www.' + t('common:MeinWohnmobil.com')} />
                    <div itemProp="aggregateRating" itemScope itemType="http://schema.org/AggregateRating">
                        <div className="row reviewsHeader">
                            <div className="headerLeft align-bottom">
                                <ReviewStars stars={avgStars} />
                                    <span className="pl-2 font-weight-light stars-text">
                                        <small>
                                            <span itemProp="ratingValue">{avgStars.toFixed(1)}</span>/<span itemProp="bestRating">5.0</span>
                                        </small>
                                    </span>
                            </div>
                            <div className="col">
                                <p className='font-weight-light'>
                                    <small><span itemProp="reviewCount">{(reviews.length)}</span> {t('reviews')}</small>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p>
                            {t('reviewsText')}
                            </p>
                        </div>                    
                    </div>
                    {
                        reviews.map((r, idx) => (
                            <Review 
                                key={idx.toString()}
                                t={t}
                                data={r} 
                                isLast={(r===lastElement)}
                            />
                        ))
                    }
                </div>
                {/* <ReviewForm /> */}
            </div>
      </div>
     );
} 
 
export default withTranslation('common')(Reviews);