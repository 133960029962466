import React, { createContext, useState } from "react";
import {
  reviews as reviewsApi,
  campers as campersApi,
  seasons as seasonsApi,
  specials as specialsApi,
  blockedDates as blockedDatesApi,
} from './components/ApiServices/ApiServices';
import * as moment from 'moment';

export const RentalContext = createContext({});
export const Provider = props => {
  // Initial values are obtained from the props
  const {
    children
  } = props;

  // Use State to keep the values
  //
  // reviews
  //
  const [reviews, setReviews] = useState(null);
  const [reviewsLoading, setReviewsLoading] = useState(null);
  const loadReviews = () => {
    if (!reviews && !reviewsLoading) {
      setReviewsLoading(true);
      const fetchData = async () => {
        const data = await reviewsApi();
        
        // sort reviews by date
        data.sort(function(first, second) {
          return moment(second.date).format("YYYYMMDD") - moment(first.date).format("YYYYMMDD");
        });
        
        setReviews(data);
        setReviewsLoading(false);
      };
      fetchData();
    }
  };

  //
  // campers
  //
  const [campers, setCampers] = useState(null);
  const [campersLoading, setCampersLoading] = useState(false);
  const loadCampers = () => {
      if (!campers && !campersLoading) {
        setCampersLoading(true);
        const fetchData = async () => {
          const data = await campersApi();
          setCampers(data);
          setCampersLoading(false);
        };
      fetchData();
    }
  };

  //
  // specials
  //
  const [specials, setSpecials] = useState(null);
  const [specialsLoading, setSpecialsLoading] = useState(false);
  const loadSpecials = () => {
      if (!specials && !specialsLoading) {
        setSpecialsLoading(true);
        const fetchData = async () => {
          const data = await specialsApi();
          setSpecials(data);
          setSpecialsLoading(false);
        };
      fetchData();
    }
  };

  //
  // blockedDates
  //
  const [blockedDates, setBlockedDates] = useState(null);
  const [blockedDatesLoading, setBlockedDatesLoading] = useState(false);
  const loadBlockedDates = () => {
      if (!blockedDates && !blockedDatesLoading) {
        setBlockedDatesLoading(true);
        const fetchData = async () => {
          const data = await blockedDatesApi();
          setBlockedDates(data);
          setBlockedDatesLoading(false);
        };
      fetchData();
    }
  };

  //
  // seasons
  //
  const [seasons, setSeasons] = useState(null);
  const [seasonsLoading, setSeasonsLoading] = useState(false);
  const loadSeasons = () => {
    if (!seasons && !seasonsLoading) {
      setSeasonsLoading(true);
      const fetchData = async () => {
        const data = await seasonsApi();
        setSeasons(data);
        setSeasonsLoading(false);
      };
      fetchData();
    }
  };
  
  //
  // rentalDates
  //
  const [rentalDates, setRentalDates] = useState({pickupDate:null, dropDate: null});

  // Make the context object:
  const rentalContext = {
    reviews,
    loadReviews,
    reviewsLoading,
    
    campers,
    loadCampers,
    campersLoading,
    
    specials,
    loadSpecials,
    specialsLoading,
    
    blockedDates,
    loadBlockedDates,
    blockedDatesLoading,
    
    seasons,
    loadSeasons,
    seasonsLoading,
    
    rentalDates,
    setRentalDates,
  };

  // pass the value in provider and return
  return <RentalContext.Provider value={rentalContext}>{children}</RentalContext.Provider>;
};

export const { Consumer } = RentalContext;
