import React, { useContext, useEffect, useState } from 'react';
import './MyDateRangePicker.css';
import { useNavigate } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { RentalContext } from '../../context';
import moment from 'moment';
import omit from 'lodash/omit';
import {DayPickerRangeController } from 'react-dates';
import {HORIZONTAL_ORIENTATION } from 'react-dates/constants';
import isInclusivelyAfterDay from '../../utils/isInclusivelyAfterDay';
import {lastDayOfSeasons} from '../../utils/seasonUtils';
import {isAfterDay, isBeforeDay, isSameDay, isDayBlocked, isValidRange, blockedDaysFromBlockedDates } from '../../utils/dateUtils';
import 'moment/locale/de';
import 'moment/locale/pt';

export function formatDateDisplay(lang, date, defaultText) {
  if (!date) return defaultText;
  // if locale then format...
  return date.locale(lang).format('L');
}

function DateInput(props) {
  return (
    <label className="sf-date-label">
      <span className="icon"><FontAwesomeIcon icon={["far", "calendar-alt"]} /></span>
      <input
        {...props}
        type="text"
        readOnly
        value={props.value || ''}
        />
    </label>
  );
}

function minDaysAllCampers(campers) {
  const minDays = [];
  campers.map(
    camper => camper.dailyRates.map(
      dailyRate => dailyRate.rates.map(
        rate => minDays.push(rate.minDays)
      )
    )
  );
  return [...new Set(minDays)];
}

function minDaysMinimum(minDays) {
  const initialMinDays = 10;
  return minDays.reduce(
    (accumulator, currentValue) => (accumulator<currentValue) ? accumulator : currentValue , initialMinDays
  );
}

function MyDateRangePicker(props) {
  const {t, i18n, startDatePlaceholderText, endDatePlaceholderText, ignoreInvalidDates, unavailableDatesMsg, linkTo, small, camper} = props;

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);
  const [focusedInput, setFocusedInput] = useState('startDate');

  const context = useContext(RentalContext);
  const { 
    rentalDates, setRentalDates, 
    campers, loadCampers, 
    seasons, loadSeasons, 
    blockedDates, loadBlockedDates,
   } = context;

  // when mounted, try to load camper data, if not already there
  useEffect(() => {
    loadCampers();
  });

  useEffect(() => {
    loadSeasons();
  });

  useEffect(() => {
    loadBlockedDates();
  });

  const navigate = useNavigate();

  if (!campers || !seasons || !blockedDates) {
    return null;
  }

  // console.log('[MDRP] rendering');

  const lastDay = lastDayOfSeasons(seasons);
  let blockedDays = [];

  if (camper) {
    blockedDays = blockedDaysFromBlockedDates(blockedDates, camper);
  }

  const onCancel = () => {
    setRentalDates({pickupDate: null, dropDate: null});
    // when a daterange was chosen, it is set to null; for a restart, we need to set it back to init
    setFocusedInput('startDate');
    toggleModal();
  };
  const onSubmit = (navigate, linkTo) => {
    // toogle the modal boolean
    if (linkTo && navigate) {
      navigate(linkTo);
    }
    toggleModal();
  };
  const onDatesChange = ({ startDate, endDate }) => {
    if (startDate && endDate) {
      // when not ignoring invalidDates, check if the selected range contains blocked days
      if (!isValidRange(startDate, endDate, blockedDays, seasons)) {
        setRentalDates({pickupDate: null, dropDate: null});
        return;
      }
    }
    setRentalDates({ pickupDate:startDate, dropDate:endDate });
  };  
 
  const { pickupDate, dropDate } = rentalDates;

  const defaults = {
    // example props for the demo
    autoFocusEndDate: false,
    initialStartDate: null,
    initialEndDate: null,
    startDateOffset: undefined,
    endDateOffset: undefined,
    showInputs: false,
    minDate: null,
    maxDate: null,
  
    // day presentation and interaction related props
    renderCalendarDay: undefined,
    renderDayContents: null,
    minimumNights: 3,
    isDayBlocked: () => false,
    isOutsideRange: day => !isInclusivelyAfterDay(day, moment()),
    isDayHighlighted: () => false,
    enableOutsideDays: false,
  
    // calendar presentation and interaction related props
    orientation: HORIZONTAL_ORIENTATION,
    verticalHeight: undefined,
    withPortal: false,
    initialVisibleMonth: null,
    numberOfMonths: 1,
    onOutsideClick() {},
    keepOpenOnDateSelect: false,
    renderCalendarInfo: null,
    isRTL: false,
    renderMonthText: null,
    renderMonthElement: null,
  
    // navigation related props
    navPrev: null,
    navNext: null,
    onPrevMonthClick() {},
    onNextMonthClick() {},
  
    // internationalization
    monthFormat: 'MMMM YYYY',
  };
    
  const defaultProps = omit(defaults, [
    'autoFocus',
    'autoFocusEndDate',
    'initialStartDate',
    'initialEndDate',
    'showInputs',
  ]);

  const Inputs_normal = (
    <div className="row">
      <div className="col-6 pr-0">
        <DateInput className="left" placeholder={startDatePlaceholderText} value={formatDateDisplay(i18n.language,pickupDate)} onClick={toggleModal} />
      </div>
      <div className="col-6 pl-0">
        <DateInput className="right" placeholder={endDatePlaceholderText} value={formatDateDisplay(i18n.language,dropDate)} onClick={toggleModal} />
      </div>
    </div>
  );
  const Inputs_small = (
    <div className="form-row small">
      <div className="form-group col-6 pr-0">
        <label className="text-capitalize m-0">{t('common:from')}</label>
        <DateInput 
          className="form-control form-control-sm" 
          placeholder={startDatePlaceholderText} 
          value={pickupDate ? formatDateDisplay(i18n.language,pickupDate): ''} 
          onClick={toggleModal}
          required              
          autoComplete="off" 
        />
      </div>
      <div className="form-group col-6 pl-0">
        <label className="text-capitalize m-0">{t('common:to')}</label>
        <DateInput 
          className="form-control form-control-sm" 
          placeholder={endDatePlaceholderText} 
          value={dropDate ? formatDateDisplay(i18n.language,dropDate): ''} 
          onClick={toggleModal}
          required              
          autoComplete="off" 
        />
      </div>
    </div>
  );

  const Inputs = (small) ? Inputs_small : Inputs_normal;
  const UnavailableDatesWarning = (unavailableDatesMsg) ? <div className="mx-3 alert alert-warning" role="alert">{unavailableDatesMsg}</div> : '';
  // depending on linking to a new page, set a specific language variable
  const buttonLangVar = (linkTo) ? 'chooseCaravan' : 'Apply';
  
  // determine from seasons
  const minDays = minDaysMinimum(minDaysAllCampers(campers));       
  
  const ModalRendered = (
      <Modal isOpen={modal} toggle={toggleModal} className={props.className}>
        <ModalHeader toggle={toggleModal}>{t('common:RentalPeriod')}</ModalHeader>
        <ModalBody className="row justify-content-md-center">
            {UnavailableDatesWarning}
            {/* show this not before the blocked days are loaded */}
            <DayPickerRangeController
              {...defaultProps}
              minimumNights={minDays-1}
              onDatesChange={onDatesChange}
              onFocusChange={focusedInput => setFocusedInput(focusedInput)}
              startDate={pickupDate}
              endDate={dropDate}
              transitionDuration={0}
              focusedInput={focusedInput}
              isDayBlocked={(ignoreInvalidDates ? () => false : (day) => isDayBlocked(day, blockedDays))}
              isOutsideRange={(day) => isAfterDay(day, lastDay) || isBeforeDay(day, moment()) || isSameDay(day, moment())}
            />
        </ModalBody>
        <ModalFooter>
          <Button className="text-capitalize" color="secondary" onClick={onCancel}>{t('common:Cancel')}</Button>{' '}
          <Button className="text-capitalize" color="primary" onClick={() => onSubmit(navigate, linkTo)}>{t('common:'+buttonLangVar)}</Button>{' '}
        </ModalFooter>
      </Modal>
  );

  return (
    <div className="MyDateRangePicker">
      {Inputs}
      {(modal ? ModalRendered : null)}
    </div>
  );
  
}

export default withTranslation('common')(MyDateRangePicker);